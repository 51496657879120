import React from 'react';
import { Concept } from '../core/models/Concept';
import { Category } from '../core/models/Category';
import { Table } from './shared/Table';
import { useTranslation } from 'react-i18next';
import { P } from './shared/P';

type Props = {
    concepts: Concept[];
    clickedCategory: Category | null;
    clickedDate: moment.Moment | null;
};

export const ModalTable = ({
    concepts,
    clickedCategory,
    clickedDate,
}: Props) => {
    const { t } = useTranslation();

    const total =
        Math.round(
            concepts
                .filter(
                    (concept) =>
                        clickedCategory &&
                        clickedDate &&
                        concept.category === clickedCategory.name &&
                        concept.date
                            .format('YYYY-MM')
                            .includes(clickedDate.format('YYYY-MM'))
                )
                .reduce(
                    (totalPrice, concept) => totalPrice + concept.price,
                    0
                ) * 100
        ) / 100;

    return (
        <Table.Root>
            <Table.Header>
                <Table.Row>
                    <Table.Cell isHeader>{t('concept.fields.date')}</Table.Cell>
                    <Table.Cell isHeader>
                        {t('concept.fields.description')}
                    </Table.Cell>
                    <Table.Cell isHeader className="text-end">
                        {t('concept.fields.price')}
                    </Table.Cell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {concepts
                    .filter(
                        (concept) =>
                            clickedCategory &&
                            clickedDate &&
                            concept.category === clickedCategory.name &&
                            concept.date
                                .format('YYYY-MM')
                                .includes(clickedDate.format('YYYY-MM'))
                    )
                    .sort((a, b) => (a.date > b.date ? 1 : -1))
                    .map((concept, index) => (
                        <Table.Row key={index}>
                            <Table.Cell className='text-nowrap'>
                                {concept.date.format('YYYY-MM-DD')}
                            </Table.Cell>
                            <Table.Cell>{concept.description}</Table.Cell>
                            <Table.Cell className="text-end">
                                {concept.price.toFixed(2)}€
                            </Table.Cell>
                        </Table.Row>
                    ))}
                <Table.Row>
                    <Table.Cell>
                        <P uppercase bold>{t('concept.total')}</P>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell className="text-end">
                        <b>{total.toFixed(2)}€</b>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table.Root>
    );
};
