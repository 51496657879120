import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import {
    ColorTypeSmall,
    ColorTypeBig,
    MarginType,
    PaddingType,
    RoundedType,
} from './type/BootstrapType';

type Props = {
    border?: ColorTypeSmall;
    background?: ColorTypeBig;
    text?: ColorTypeBig;
    rounded?: RoundedType;
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    role?: string;
    m?: MarginType;
    p?: PaddingType;
};

export const Box = ({
    children,
    className,
    style,
    border,
    background,
    text,
    rounded,
    onClick,
    role,
    m = 0,
    p = 0,
}: Props) => {
    return (
        <div
            className={clsx('card', className, `m-${m}`, `p-${p}`, {
                [`border-${border}`]: border,
                [`bg-${background}`]: background,
                [`rounded-${rounded}`]: rounded,
                [`text-${text}`]: text,
            })}
            onClick={onClick}
            role={role}
            style={style}
        >
            {children}
        </div>
    );
};
