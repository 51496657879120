import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { IconBootstrap } from '../Icon';
import { H5 } from '../H';
import { MarginType, PaddingType } from '../type/BootstrapType';

type RootProps = {
    children: ReactNode;
    show: boolean;
};

const Root = ({ children, show }: RootProps) => {
    return (
        <div
            className={clsx('modal fade', {
                show: show,
                'd-block': show,
            })}
            style={{ background: 'RGB(0, 0, 0, 0.8)' }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">{children}</div>
            </div>
        </div>
    );
};

type HeaderProps = {
    title: string;
    onClose: () => void;
    m?: MarginType;
    p?: PaddingType;
    mx?: MarginType;
    px?: PaddingType;
};

const Header = ({
    title,
    onClose,
    m = 0,
    p = 0,
    mx = 2,
    px = 0,
}: HeaderProps) => {
    return (
        <div
            className={clsx(
                'modal-header d-flex justify-content-between',
                `m-${m}`,
                `p-${p}`,
                `mx-${mx}`,
                `px-${px}`
            )}
        >
            <H5 className="modal-title">{title}</H5>
            <IconBootstrap name="x" size="xl" onClick={onClose} />
        </div>
    );
};

type BodyProps = {
    children: ReactNode;
    m?: MarginType;
    p?: PaddingType;
};

const Body = ({ children, m = 0, p = 2 }: BodyProps) => {
    return (
        <div className={clsx('modal-body', `m-${m}`, `p-${p}`)}>{children}</div>
    );
};

type FooterProps = {
    children: ReactNode;
    m?: MarginType;
    p?: PaddingType;
};

const Footer = ({ children, m = 0, p = 2 }: FooterProps) => {
    return (
        <div className={clsx('modal-footer', `m-${m}`, `p-${p}`)}>
            {children}
        </div>
    );
};

export const Modal = { Root, Header, Body, Footer };
